import * as React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import Helmet from 'react-helmet'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faComment, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import '../styles/_bootstrap.scss'
import './index.scss';
import './make-payment.scss';

class Payment extends React.PureComponent<{}, {}> {
    constructor(props) {
        super(props);
        this.state = {
            _ref: React.createRef()

        }
    }
    render() {
        return (
            <>
                <Header />
                <Helmet>
                    <script src="globalincludes/cookiecheck/cookiecheck.js"></script>
                </Helmet>
                <div className="page">
                    <div className="container-fluid hero">
                        <section className="section hero-small background-gradient">
                            <div className="hero-no-image padding-top-XL padding-bottom-M container">
                                <h1 className="text-left">Credit Your Account</h1>
                            </div>
                        </section>
                    </div>
                    <div className="container body">
                        <section className="section payments">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <div>
                                        <p className="text-M">You can make a payment online to pay outstanding invoices, clear your current balance or to release a Cash on Delivery order.
                                        <br /><br />Enter your account code and amount to credit, then proceed to entering your card details.
                                        <br /><br />Credit card payments are not supported online, please call 01494 768666 to make arrangements. See <a href="https://www.direktek.co.uk/help/returns">terms</a> for more information.</p>
                                    </div>
                                    <div className="contact-form text-center" style={{ padding: "10px" }}>
                                        <p className="text-M text-center">Have a question about payments?</p><a className="pointer text-center" onClick={() => window.alert('Our chat is currently down')}>
                                            <FontAwesomeIcon icon={faComment} size="2x"/></a> <a className="pointer text-center" href="mailto:creditcontrol@direktek.co.uk?subject=Payments%20Query"><FontAwesomeIcon icon={faEnvelope} size="2x"/></a>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div>
                                        <div className="panel panel-default" style={{ borderRadius: "0 !important" }}>
                                            <div className="panel-body">
                                                <form action="https://secure.worldpay.com/wcc/purchase" id="mpayment_form" method="post" name="mpayment_form" target="_blank">
                                                    <fieldset>
                                                        <input name="testMode" type="hidden" value="0" />
                                                        <input name="authMode" type="hidden" value="A" />
                                                        <input name="instId" type="hidden" value="304806" />
                                                        <input name="currency" type="hidden" value="GBP" />
                                                        <input name="desc" type="hidden" value="Account Credit Payment" />
                                                        <input name="name" type="hidden" value="" />
                                                        <div className="form-group">
                                                            <label>Your customer code:</label> <input className="form-control" id="cartId" name="cartId" placeholder="ABC123" required={true} type="value" />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Amount (&#163;):</label> <input className="form-control" id="amount" name="amount" placeholder="299.99" required={true} type="value" />
                                                        </div>
                                                        <input className="btn btn-success btn-block squared" type="submit" value="Top up now" />
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                        <div id='wp-widget' className="col-xs-12 powered-wp text-center">
                                            <table cellSpacing={2} align="center">
                                                <tr>
                                                    <td><a href="http://www.mastercard.com" target="_blank"><img src="https://secure.worldpay.com/jsp/shopper/icons/WP_ECMC.gif" alt="Mastercard" style={{padding: 1 + "px"}} /></a></td>
                                                    <td><a href="http://www.maestrocard.com" target="_blank"><img src="https://secure.worldpay.com/jsp/shopper/icons/WP_MAESTRO.gif" alt="Maestro" /></a></td>
                                                    <td><a href="http://www.visa.com" target="_blank"><img src="https://secure.worldpay.com/jsp/shopper/icons/WP_VISA_DELTA.gif" alt="Visa Debit" /></a></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3} align="center"><a href="http://www.worldpay.com/support/index.php?CMP=BA22713" target="_blank"><img src="https://secure.worldpay.com/jsp/shopper/icons/../pictures/poweredByWorldPay.gif" alt="Powered by WorldPay" /></a></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
    private generateScript = () => {
        return { __html: "<script language='JavaScript' src='https://secure.worldpay.com/wcc/logo?instId=304806'></script>" };
    }
    private getURLParameterByName(name) {
        name = name.replace(/[\[]/, "\[").replace(/[\]]/, "\]");
        var regex = new RegExp("[\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

    private removeEmptyInputs() {
        var allInputs = document.querySelector('#mpayment_form input[type=text]');
        var input, i;
        for (i = 0; input = allInputs[i]; i++) {
            if (input.getAttribute('name') && !input.value) {
                input.setAttribute('name', '');
            }
        }
    };
    private resetEmptyInputs() {
        var allInputs = document.querySelector('#mpayment_form input[type=text]');
        var input, i;

        for (i = 0; input = allInputs[i]; i++) {
            input.setAttribute('name', input.id);
        }
    };

    //   if (this.getURLParameterByName("amount")) {
    //     document.getElementById("amount").value = getURLParameterByName("amount");
    //     removeEmptyInputs();
    //     resetEmptyInputs();
    //   }
    //   if (getURLParameterByName("customer_code")) {
    //     document.getElementById("cartId").value = getURLParameterByName("customer_code");
    //     removeEmptyInputs();
    //     resetEmptyInputs();
    //   }
    componentDidMount() {
        const element = document.querySelector('.powered-wp');
        const script = document.createElement('script');
        console.log(`element before if ${element}`)
        script.id = 'wp';
        script.src = 'https://secure.worldpay.com/wcc/logo?instId=304806';
        script.async = true;
    }
}

export default Payment;